import React, { useEffect, Suspense, lazy } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import '@splidejs/react-splide/css';
import { hotjar } from 'react-hotjar';

import Topbar from './components/Topbar/Topbar';
import Footer from './components/Footer/Footer';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Dashboard from './Pages/Dashboard';
import DashboardDetail from './Pages/Dashboard/detail';
import DashboardProjects from './Pages/Dashboard/projects';
// import AuthView from './Pages/Auth';
// import ResetPass from './Pages/Auth/resetPass';
// import VerifyReset from './Pages/Auth/verifyReset';
// import SuscriptionModel from './Pages/SuscriptionModel';
import Instalments from './Pages/Instalments';
import Payment from './Pages/Payment';
import Invest from './Pages/Payment/Invest';
import PersonalData from './Pages/Payment/PersonalData';
import Complete from './Pages/Payment/Complete';
import Success from './Pages/Payment/Success';
import Error from './Pages/Payment/Error';
import SummaryInvest from './Pages/Payment/SummaryInvest';
import Identity from './Pages/Payment/Identity';
import Contract from './Pages/Payment/Contract';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { on } from './helpers/events';
import tatiana from "./assets/img/home/foto-tatiana-lokl.png";
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import Reset from './Pages/Auth/Reset';
import Verify from './Pages/Auth/Verify';
import Webinars from './Pages/Webinars';
import Blog from './Pages/Blog';
import Article from './Pages/Blog/Article';
import { setUnitPrice } from './helpers/functions';
import ConfirmationCode from './Pages/Auth/ConfirmationCode';
import ArticleHospitality from './Pages/Blog/ArticleHospitality';
import ArticlePresupuesto from './Pages/Blog/ArticlePresupuesto';
import ArticleSectores from './Pages/Blog/ArticleSectores';
import ArticleOpcionesInversion from './Pages/Blog/ArticleOpcionesInversion';
import ArticleInversionAhorro from './Pages/Blog/ArticleInversionAhorro';
import ArticleConceptosBasicosDeInversion from './Pages/Blog/ArticleConceptosBasicosDeInversion';
import ArticleInvertirEnBienesRaices from './Pages/Blog/ArticleInvertirEnBienesRaices';
import ArticleOrganizarMisFinanzas from './Pages/Blog/ArticleOrganizarMisFinanzas';
import { ArticleBody } from './Pages/Blog/ArticleBody';
import { articles } from './Pages/Blog/Articles';

/* const Home = lazy(() => import('./Pages/Home')); */
const NewHome = lazy(() => import('./Pages/NewHome')); 
// const NidoAgua = lazy(() => import('./nidoAgua'));
const NidoDeAgua = lazy(() => import('./Pages/NidoDeAgua'));
const IndieUniverse = lazy(() => import('./indieUniverse'));

function App() {
  const btnWhatsapp = () => {
    return (
      <FloatingWhatsApp
        phoneNumber="573207379535"
        accountName="Luis Felipe"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={tatiana}
        statusMessage="En línea"
        chatMessage="Hola! Soy Luis Felipe 😊 Tu asesor en inversiones inmobiliarias. Cuál es tu nombre?"
        placeholder="Escríbenos un mensaje"
      />
    );
  }

  const validateRedirect = () => {

    const url = new URL(window.location.href);

    const redirect = url.searchParams.get("redirect_to");

    if (redirect) {

      window.location.href = redirect;

    }


  }

  useEffect(() => {

    // consultar nuevo precio del unit
    setUnitPrice();

    // Function to clear complete cache data
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    // parte de hotjar
    hotjar.initialize(2663938, 6);

    on('authed', () => {
      validateRedirect();
    });

    const url = window.location.href;
    const parameter = new URL(url);
    const code = parameter.searchParams.get('code');

    if (code && !url.includes('/payment/complete') && !url.includes('/checkout/personal-data')) {
      localStorage.setItem('referral_code', code);
    }

    const urlDashboardPattern = /^\/dashboard\/([^/]+)$/;

    if (!urlDashboardPattern.test(window.location.pathname)) {
      localStorage.removeItem("projects");
      localStorage.removeItem("name");
    }

  }, []);

  return (
    <Router>

      <Suspense>

        <div>

          {/* es la barra superior para navegar por la página */}
          <Topbar />

          {btnWhatsapp()}

          <Routes>

            {/*<Route
              path='/'
              element={<Home />}
            />*/}

            <Route
              path='/'
              element={<NewHome />}
            />

            <Route
              path='/indie-universe'

              element={<IndieUniverse />}
            />

            <Route
              path='/nido'
              element={<NidoDeAgua />}
            />

           {/*  <Route
              path='/nido-de-agua'
              element={<NidoAgua />}
            /> */}

             <Route
              path='/nido-de-agua'
              element={<NidoDeAgua />}
            />

            <Route
              path='/dashboard'
              element={<Dashboard />}
            >

              <Route
                path=''
                element={<DashboardProjects />}
              />

              <Route
                path=':projectId'
                element={<DashboardDetail />}
              />

            </Route>

            {/* <Route
            path="/modelo-suscripcion"
            element={<SuscriptionModel/>}
          /> */}

            <Route
              path="/cuotas-suscripcion"
              element={<Instalments />}
            />

            <Route
              path="/webinars"
              element={<Webinars />}
            />

            <Route
              path="/checkout"
              element={<Payment />}
            >

              <Route
                path='invest'
                element={<Invest />}
              />

              <Route
                path='summary-invest'
                element={<SummaryInvest />}
              />

              <Route
                path='personal-data'
                element={
                  // <DndProvider backend={HTML5Backend}>
                  <PersonalData />
                  // </DndProvider>
                }
              />

              <Route
                path='identity'
                element={
                  <DndProvider backend={HTML5Backend}>
                    <Identity />
                  </DndProvider>
                }
              />

              <Route
                path='contract'
                element={<Contract />}
              />

            </Route>

            <Route
              path="/payment"
              element={<Payment />}
            >

              <Route
                path='complete'
                element={<Complete />}
              />

              <Route
                path='successful'
                element={<Success />}
              />

              <Route
                path='error'
                element={<Error />}
              />

            </Route>

            <Route
              path='/login'
              element={<Login />}
            />

            <Route
              path='/register'
              element={<Register />}
            />

            <Route
              path='/confirmation-code'
              element={<ConfirmationCode />}
            />

            <Route
              path="/reset-password"
              element={<Reset />}
            />

            <Route
              path="/new-password"
              element={<Verify />}
            />

            <Route
              path="/blog"
              element={<Blog />}
            />

            <Route
              path="/blog/tips-inversion-finanzas/conceptos-basicos"
              element={<ArticleConceptosBasicosDeInversion />}
            />

            <Route
              path="/blog/donde-invertir-colombia-2023"
              element={<Article />}
            />

            <Route
              path="/blog/inversiones-colaborativas-hospitality"
              element={<ArticleHospitality />}
            />

            <Route
              path="/blog/planificar-presupuesto-largo-plazo"
              element={<ArticlePresupuesto />}
            />

            <Route
              path="/blog/sectores-invertir-colombia-2023"
              element={<ArticleSectores />}
            />

            <Route
              path="/blog/opciones-de-inversion-colombia"
              element={<ArticleOpcionesInversion />}
            />

            <Route
              path="/blog/inversion-ahorro-crecimiento-financiero"
              element={<ArticleInversionAhorro />}
            />
            <Route
              path="/blog/invertir-en-bienes-raices"
              element={<ArticleInvertirEnBienesRaices />}
            />
            <Route
              path="/blog/organizar-mis-finanzas"
              element={<ArticleOrganizarMisFinanzas />}
            />

            {
              articles.map((article) => {
                return (
                  <Route
                    path={`/blog/${article.urlRouter}`}
                    element={
                      <ArticleBody
                        key={article.urlRouter}
                        title={article.title}
                        titleHeader={article.titleHeader}
                        descriptionHeader={article.descriptionHeader}
                        articleCards={article.articleCards}
                      />
                    }
                  />
                )
              })
            }

          </Routes>

          {/* es la barra inferior para navegar por la página */}

          <Footer />

        </div>

      </Suspense>

    </Router>
  );

}

export default App;
