import React, { createContext, useState, ReactNode } from 'react';
import { ProjectCardDataInterface } from '../Pages/NewHome/interfaces/ProjectCardDataInterface';

interface ProjectsContextInterface {
    projectCards: ProjectCardDataInterface[];
    setProjectCards: (value: ProjectCardDataInterface[]) => void;
}

const defaultValue: ProjectsContextInterface = {
    projectCards: [],
    setProjectCards: () => { },
};

export const ProjectsContext = createContext<ProjectsContextInterface>(defaultValue);


interface ProjectProviderProps {
    children: ReactNode;
}

export const ProjectProvider: React.FC<ProjectProviderProps> = ({ children }) => {
    const [projectCards, setProjectCards] = useState<ProjectCardDataInterface[]>([]);

    return (
        <ProjectsContext.Provider value={{ projectCards, setProjectCards }}>
            {children}
        </ProjectsContext.Provider>
    );
};
