import React, { createContext, useContext, useState } from "react";

interface VideoContextProps {
    activeVideo: string | null;
    setActiveVideo: (videoId: string | null) => void;
}

const VideoContext = createContext<VideoContextProps>({
    activeVideo: null,
    setActiveVideo: () => { }
});

export const useVideoContext = () => useContext(VideoContext);

export const VideoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [activeVideo, setActiveVideo] = useState<string | null>(null);

    return (
        <VideoContext.Provider value={{ activeVideo, setActiveVideo }}>
            {children}
        </VideoContext.Provider>
    );
};
